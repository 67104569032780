import styled from 'styled-components';
import Layout from 'components/Layout';
import React from 'react';
import About from './About';
import Hero from './Hero';
import Projects, { IProjectProps } from './Projects';
import Services from './Services';
import { device } from 'styles/theme';
import ContactSection from 'components/organinsms/ContactSection';

export type HomePageProps = {
  projects: IProjectProps[];
};

function HomePageTemplate({ projects }: HomePageProps) {
  return (
    <Layout>
      <Hero />
      <Section>
        <About />
      </Section>
      <Section>
        <Services />
      </Section>
      <Section>
        <Projects projects={projects} />
      </Section>
      <Section>
        <ContactSection />
      </Section>
    </Layout>
  );
}

const Section = styled.section`
  margin-top: 100px;
  @media ${device.mobileL} {
    margin-top: 150px;
  }
  @media ${device.tablet} {
    margin-top: 300px;
  }
`;

export default HomePageTemplate;
