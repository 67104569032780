import React from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import WrapperContainer from '@atoms/WrapperContainer';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { device } from 'styles/theme';

gsap.registerPlugin(ScrollTrigger);

function About() {
  const aboutRef = React.useRef(null);
  React.useEffect(() => {
    ScrollTrigger.batch('.txt-line', {
      onEnter: elements => {
        gsap.from(elements, {
          duration: 1,
          y: 400,
          skewY: 10,
          stagger: {
            amount: 0.5,
          },
        });
      },
      once: true,
    });
    gsap.to('.cover', {
      height: 0,
      ease: 'expo.inOut',
      duration: 2.4,
      scrollTrigger: {
        trigger: '.cover',
        start: 'top 90%',
      },
    });
  }, []);

  return (
    <WrapperContainer>
      <AboutContainer ref={aboutRef}>
        <AboutContentContainer>
          <AboutContent>
            <Line>
              <p className="txt-line">
                My name is Sergey Gridin. I am a developer from Toronto, Canada.
              </p>
            </Line>
            <Line>
              <p className="txt-line">
                Great presentation of the business is a key to success. And I am here to help
              </p>
            </Line>
            <Line>
              <p className="txt-line">
                Working with latest technologies, I aim to bring businesses solutions to their
                problems in a unique, creative way.
              </p>
            </Line>
          </AboutContent>
          <ImageContainer>
            <ImageCover className="cover" />
            <StaticImage
              src="./../../../assets/images/about.jpeg"
              style={{
                width: '100%',
              }}
              layout="fullWidth"
              alt=""
            />
          </ImageContainer>
        </AboutContentContainer>
      </AboutContainer>
    </WrapperContainer>
  );
}

const AboutContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 70%;
`;

const Line = styled.div`
  margin-top: 30px;
  font-size: calc(1rem + 1vw);
  overflow: hidden;
  p {
    position: relative;
    overflow: hidden;
  }
`;

const AboutContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  @media ${device.mobileL} {
    width: 60%;
  }
  @media ${device.tablet} {
    margin-top: unset;

    display: flex;
    flex: 0 1 30%;
    justify-content: flex-end;
  }
`;

const ImageCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
`;

export default About;
