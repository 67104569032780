import React from 'react';
import SEO from 'components/SEO';
import HomePageTemplate, { HomePageProps } from 'components/templates/HomePage';
import { graphql, PageProps } from 'gatsby';
import { ProjectsQuery } from '../../gatsby-graphql';
import CustomCursor from 'components/CustomCursor';

const seo = {
  metaDescription: 'Home Page',
  metaTitle: 'Sergey Gridin',
};

export default function Home({ data }: PageProps<ProjectsQuery>) {
  const homePageProps: HomePageProps = {
    projects: data.allContentfulProject.nodes as any,
  };

  return (
    <>
      <SEO lang="en" seo={{ ...seo }} />
      <CustomCursor />
      <HomePageTemplate {...homePageProps} />
    </>
  );
}

export const query = graphql`
  query Projects {
    allContentfulProject {
      nodes {
        services
        title
        slug
        previewImage {
          gatsbyImageData
        }
      }
    }
  }
`;
