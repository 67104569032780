import React, { useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import WrapperContainer from '@atoms/WrapperContainer';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from 'styles/theme';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';

function GridItemCover({ tl }: { tl: GSAPTimeline }) {
  const divRef = useRef(null);

  useLayoutEffect(() => {
    const animation = gsap.to(divRef.current, {
      height: '0',
      duration: 0.5,
    });
    tl.add(animation, Math.random() * 2);
  }, []);

  return (
    <div
      style={{
        display: 'inline-block',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
      }}
      ref={divRef}
    />
  );
}

function GridCell({ tl }: { tl: GSAPTimeline }) {
  const cellRef = useRef(null);
  const hover = (e: any) => {
    gsap.to(e.currentTarget.children[0], { height: '100%' });
  };
  const leave = (e: any) => {
    gsap.to(e.currentTarget.children[0], { height: '0' });
  };
  return (
    <GridItem ref={cellRef} onMouseEnter={hover} onMouseLeave={leave}>
      <GridItemCover tl={tl} />
    </GridItem>
  );
}

function Row({ id, tl }: { id: string; tl: GSAPTimeline }) {
  const countArray = Array.from(Array(14).keys());

  return (
    <GridRow id={id}>
      {countArray.map(i => (
        <GridCell key={i} tl={tl} />
      ))}
    </GridRow>
  );
}

function Hero() {
  const tl = useRef(gsap.timeline());

  useIsomorphicLayoutEffect(() => {
    gsap.from('.letter', {
      y: 200,
      skewY: 10,
      duration: 0.5,
      stagger: {
        amount: 1,
        ease: 'power1.out',
      },
    });
  });

  return (
    <WrapperContainer>
      <HeroContainer>
        <HeroImageContainer>
          <StaticImage
            src="./../../../assets/images/hero.png"
            style={{
              width: '100%',
              height: '100%',
            }}
            layout="fullWidth"
            alt=""
          />
        </HeroImageContainer>
        <HeroGrid>
          <Row id="row-1" tl={tl.current} />
          <Row id="row-2" tl={tl.current} />
          <GridRow id="row-3">
            <Name>
              <span className="letter">S</span>
              <span className="letter">E</span>
              <span className="letter">R</span>
              <span className="letter">G</span>
              <span className="letter">E</span>
              <span className="letter">Y</span>
            </Name>
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
          </GridRow>
          <Row id="row-4" tl={tl.current} />
          <GridRow id="row-5">
            <Name>
              <span className="letter">G</span>
              <span className="letter">R</span>
              <span className="letter">I</span>
              <span className="letter">D</span>
              <span className="letter">I</span>
              <span className="letter">N</span>
            </Name>
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
            <GridCell tl={tl.current} />
          </GridRow>
          <Row id="row-6" tl={tl.current} />
          <DisplayRow>
            <Name>
              <span className="letter">C</span>
              <span className="letter">R</span>
              <span className="letter">E</span>
              <span className="letter">A</span>
              <span className="letter">T</span>
              <span className="letter">I</span>
              <span className="letter">V</span>
              <span className="letter">E</span>
            </Name>
          </DisplayRow>
          <DisplayRow>
            <Name>
              <span className="letter">D</span>
              <span className="letter">E</span>
              <span className="letter">V</span>
              <span className="letter">E</span>
              <span className="letter">L</span>
              <span className="letter">O</span>
              <span className="letter">P</span>
              <span className="letter">E</span>
              <span className="letter">R</span>
            </Name>
          </DisplayRow>
        </HeroGrid>
        <HeroMobile>
          <h1>
            <span>SERGEY</span>
            <span>GRIDIN</span>
          </h1>
          <h2>
            <span>CREATIVE</span>
            <span>DEVELOPER</span>
          </h2>
        </HeroMobile>
      </HeroContainer>
    </WrapperContainer>
  );
}
const HeroContainer = styled.div`
  position: relative;
  z-index: 0;
`;

const HeroMobile = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13vw;
  font-weight: 400;
  h1,
  h2 {
    display: flex;
    flex-direction: column;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

const HeroImageContainer = styled.div`
  position: relative;
  padding: 24px 0;
  height: 40vh;
  @media ${device.mobileL} {
    height: 45vh;
  }
  @media ${device.tablet} {
    position: absolute;
    padding: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
`;

const HeroGrid = styled.div`
  position: relative;
  display: none;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 24px;
  z-index: 1;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(14, 1fr);
  }
`;

const GridItem = styled.div`
  /* overflow: hidden; */
  transform-style: preserve-3d;
  position: relative;
  perspective: 800px;
  aspect-ratio: 1;
  border-right: 5px solid white;
  border-bottom: 5px solid white;

  @media ${device.tablet} {
    &:nth-last-child(-n + 4) {
      display: none;
    }
  }
  @media ${device.laptop} {
    &:nth-last-child(-n + 4) {
      display: block;
    }
  }
`;

const GridRow = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: inherit;
  ${GridItem}:nth-child(1) {
    background-color: white;
  }
  &[id='row-1'] {
    ${GridItem}:nth-child(3),${GridItem}:nth-child(5), ${GridItem}:nth-child(6) {
      background-color: white;
    }
    ${GridItem}:nth-child(10),${GridItem}:nth-child(13),${GridItem}:nth-child(14) {
      background-color: white;
    }
  }
  &[id='row-2'] {
    ${GridItem}:nth-child(4),${GridItem}:nth-child(5),${GridItem}:nth-child(7),${GridItem}:nth-child(11),${GridItem}:nth-child(13) {
      background-color: white;
    }
  }
  &[id='row-3'] {
    ${GridItem}:nth-child(2), ${GridItem}:nth-child(4),${GridItem}:nth-child(7),${GridItem}:nth-child(9) {
      background-color: white;
    }
  }
  &[id='row-4'] {
    ${GridItem}:nth-child(2),${GridItem}:nth-child(5), ${GridItem}:nth-child(7), ${GridItem}:nth-child(10) {
      background-color: white;
    }
    ${GridItem}:nth-child(10),${GridItem}:nth-child(13),${GridItem}:nth-child(14) {
      background-color: white;
    }
  }
  &[id='row-5'] {
    ${GridItem}:nth-child(3),${GridItem}:nth-child(6), ${GridItem}:nth-child(9) {
      background-color: white;
    }
  }
  &[id='row-6'] {
    ${GridItem}:nth-child(3),${GridItem}:nth-child(5), ${GridItem}:nth-child(7), ${GridItem}:nth-child(10) {
      background-color: white;
    }
    ${GridItem}:nth-child(10),${GridItem}:nth-child(13) {
      background-color: white;
    }
  }
`;

const Name = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column: 1 / span 6;
  background-color: white;

  span {
    display: flex;
    aspect-ratio: 1;
    border-right: 5px solid white;
    border-bottom: 5px solid white;
    justify-content: center;
    align-items: center;
    font-size: 5vw;
    font-weight: 400;
  }

  overflow: hidden;
  span {
    position: relative;
    overflow: hidden;
  }
  height: max-content;
`;

const DisplayRow = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: inherit;
  background-color: white;
  &:nth-last-child(2) {
    ${Name} {
      grid-template-columns: repeat(9, 1fr);
      grid-column: 1 / span 9;
    }
  }
  &:last-child {
    ${Name} {
      grid-template-columns: repeat(9, 1fr);
      grid-column: -10 / -1;
    }
  }
`;
export default Hero;
