import React from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import WrapperContainer from '@atoms/WrapperContainer';
// import brandingAnimation from 'assets/lottie/branding.json';
// import { useLottie } from 'lottie-react';
import { device } from 'styles/theme';

gsap.registerPlugin(ScrollTrigger);

function Services() {
  return (
    <WrapperContainer>
      <ServicesContainer>
        <Service title="DESIGN" animationEnter="bottom" />
        <Service title="BRANDING" animationEnter="left" />
        <Service title="DEVELOPMENT" animationEnter="top" />
      </ServicesContainer>
    </WrapperContainer>
  );
}

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10vw;
`;

export default Services;

interface IServiceProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  animationEnter: 'top' | 'left' | 'bottom';
}

const ServiceBase = ({ className, title, animationEnter }: IServiceProps) => {
  const lottieTweenVars: GSAPTweenVars = {
    duration: 0.4,
    opacity: 1,
  };
  // check animationEnter to determine direction for animation enter
  switch (animationEnter) {
    case 'bottom':
      lottieTweenVars.y = '13vw';
      break;
    case 'left':
      lottieTweenVars.x = '-35vw';
      break;
    case 'top':
      lottieTweenVars.y = '-20vw';
      break;
    default:
      break;
  }
  //
  // const animationOptions = { animationData: brandingAnimation, loop: true, autoplay: true };
  // const { View, play, stop } = useLottie(animationOptions);
  const lottieRef = React.useRef(null);
  const lottieTl = React.useRef<GSAPTimeline | null>(null);
  const tlRef = React.useRef<GSAPTimeline | null>(null);

  const spanRef2 = React.useRef(null);
  const spanRef3 = React.useRef(null);

  React.useEffect(() => {
    const tl = (tlRef.current = gsap.timeline({
      scrollTrigger: {
        trigger: spanRef2.current,
        start: 'top 80%',
      },
    }));

    const animation1 = gsap.to(spanRef2.current, {
      duration: 0.4,
      y: '-2vw',
    });
    const animation2 = gsap.to(spanRef3.current, {
      duration: 0.4,
      y: '-4vw',
    });
    tl.add(animation1, 0).add(animation2, 0);

    const lottieAnimation = gsap.to(lottieRef.current, lottieTweenVars);
    lottieTl.current = gsap.timeline({ paused: true }).add(lottieAnimation);
  }, []);

  // const hover = () => {
  //   // check target hover to prevent jerking in animation
  //   tlRef.current?.reverse();
  //   lottieTl.current?.play();
  // };

  // const leave = () => {
  //   // check target hover to prevent jerking in animation
  //   tlRef.current?.play();
  //   lottieTl.current?.reverse();
  // };
  return (
    <div
      className={className}
      //  onMouseEnter={hover}
      //   onMouseLeave={leave}
    >
      <span>{title}</span>
      <span ref={spanRef2}>{title}</span>
      <span ref={spanRef3}>{title}</span>
      {/* <AnimationContainer ref={lottieRef}>{View}</AnimationContainer> */}
    </div>
  );
};

// const AnimationContainer = styled.div`
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 30vw;
//   opacity: 0;
// `;

const Service = styled(ServiceBase)<IServiceProps>`
  position: relative;
  flex: 1 1 0;
  max-width: max-content;
  height: 100px;
  font-size: 11vw;
  z-index: 0;

  span {
    display: inline-block;
    position: relative;
    background-color: white;
    z-index: 3;
    line-height: 80%;
    &:nth-child(2) {
      position: absolute;
      top: 1vw;
      left: 0;
      z-index: 2;
    }
    &:nth-child(3) {
      position: absolute;
      top: 1vw;
      left: 0;
      z-index: 1;
    }
  }
  @media ${device.tablet} {
    font-size: 7vw;

    &:nth-child(2) {
      align-self: flex-end;
    }
  }
`;
