import WrapperContainer from '@atoms/WrapperContainer';
import Cover from 'components/animations/Cover';
// import { Link, navigate } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import gsap from 'gsap';
import React from 'react';
import styled from 'styled-components';
import { device } from 'styles/theme';

export interface IProjectProps {
  title: string;
  slug: string;
  previewImage: any;
  services: string[];
  idx?: number;
  onProjectHover: (idx: number) => void;
  onProjectLeave: () => void;
}

type ProjectsProps = {
  projects: IProjectProps[];
};

function Projects({ projects }: ProjectsProps) {
  // on hover change mouse to display "VIEW PROJECT"
  const mounted = React.useRef(false);
  const [activeImgIdx, setActiveImgIdx] = React.useState(-1);
  const previewRef = React.useRef(null);
  const previewContainerRef = React.useRef(null);
  const isAnimatingRef = React.useRef<false | 'leave' | 'enter'>(false);
  const nextAnimatingRef = React.useRef(-1);
  const animationTl = React.useRef<GSAPTimeline | null>(null);

  React.useEffect(() => {
    // mount component
    mounted.current = true;
    animationTl.current = gsap
      .timeline({
        paused: true, // pause for initial render
        onComplete: () => {
          // set animation state to be false on complete and  remove next animating image
          isAnimatingRef.current = false;
          nextAnimatingRef.current = -1;
        },
        onReverseComplete: () => {
          // when reverse completes, we wnat to set current animation state to be false.
          isAnimatingRef.current = false;

          // next we wnat to check if there is a next image to animate.
          if (nextAnimatingRef.current > -1) {
            // if there is a next image to animate, call onProjectHover with idx of the next iamge.
            // this will trigger enter animation again. Artificial hover sort of..
            onProjectHover(nextAnimatingRef.current);
            // return to prevent next lines from running
            return;
          }
          // remove active image and next image as there is no animation at this moment
          setActiveImgIdx(-1);
          nextAnimatingRef.current = -1;
        },
      })
      .to(previewRef.current, {
        width: '0%',
        ease: 'expo.inOut',
        duration: 1,
      });
  }, []);

  React.useLayoutEffect(() => {
    if (!mounted.current) {
      // not mounted, thus return to prevent animation from running on initial mount
      return;
    }

    // handles animations play/reverse on active image change
    if (isAnimatingRef.current === 'enter') {
      animationTl.current?.play();
    } else if (isAnimatingRef.current === 'leave') {
      animationTl.current?.reverse();
    }
  }, [activeImgIdx]);

  const onProjectHover = (idx: number) => {
    // this handles case when you jerk over the same image
    if (isAnimatingRef.current === 'leave' && idx === activeImgIdx) {
      animationTl.current?.play();
      return;
    }
    // if there is a current leave animation, queue next animation. It will be picked up by GSAP
    if (isAnimatingRef.current === 'leave') {
      nextAnimatingRef.current = idx;
      // return so we dont go to next lines
      return;
    }
    // we need to set it before updating state, since it happens synchronously see docs for useLayoutEffect
    isAnimatingRef.current = 'enter';
    // update state with current hover image
    setActiveImgIdx(idx);
  };

  const onProjectLeave = () => {
    nextAnimatingRef.current = -1;
    isAnimatingRef.current = 'leave';
    animationTl.current?.reverse();
  };

  const parallax = (event: any) => {
    const speed = -5;
    const x = (window.innerWidth - event.pageX * speed) / 100;
    const y = (window.innerWidth - event.pageY * speed) / 100;
    gsap.to(previewContainerRef.current, {
      translateX: x,
      translateY: y,
    });
  };

  const activeProjectPreview = projects[activeImgIdx]?.previewImage.gatsbyImageData;

  return (
    <WrapperContainer>
      <ProjectsContainer onMouseMove={parallax}>
        <ProjectPreviewWrapper ref={previewContainerRef}>
          <ProjectPreview
            className="prev"
            // onMouseEnter={() => {
            //   console.log('over');
            // }}
            //create an invisible div that will follow project preview,
            // use it to make hover effect over preview with Mouse expanding
          >
            {activeImgIdx > -1 ? (
              <GatsbyImage image={getImage(activeProjectPreview) as IGatsbyImageData} alt="" />
            ) : null}
            <ProjectPreviewCover ref={previewRef} />
          </ProjectPreview>
        </ProjectPreviewWrapper>
        <ProjectsList>
          {projects.map((project, idx) => {
            return (
              <Project
                key={project.title}
                {...project}
                idx={idx}
                onProjectHover={onProjectHover}
                onProjectLeave={onProjectLeave}
              />
            );
          })}
        </ProjectsList>
      </ProjectsContainer>
    </WrapperContainer>
  );
}

const ProjectPreviewWrapper = styled.div``;
const ProjectPreview = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40%;
  width: 30%;
`;

const ProjectPreviewCover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const ProjectsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProjectsContainer = styled.div`
  position: relative;
`;

const Project = ({ title, slug, services, onProjectHover, idx, onProjectLeave }: IProjectProps) => {
  const handleProjectHover = () => {
    if (onProjectHover && idx !== undefined) {
      onProjectHover(idx);
    }
  };

  const handleProjectLeave = () => {
    onProjectLeave();
  };

  return (
    <ProjectContainer>
      <Cover to={`/projects/${slug}`} direction="left">
        <ProjectTitle onMouseEnter={handleProjectHover} onMouseLeave={handleProjectLeave}>
          {title}
        </ProjectTitle>
      </Cover>

      <ProjectTech>
        {services.map(service => {
          return <span key={service}>{service}</span>;
        })}
      </ProjectTech>
    </ProjectContainer>
  );
};

const ProjectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid gray;
  @media ${device.tablet} {
    padding: 24px;
    flex-direction: row;
  }
`;

const ProjectTitle = styled.h3`
  font-size: 14vw;
  mix-blend-mode: difference;
  color: white;
  @media ${device.tablet} {
    font-size: 11vw;
  }
`;
const ProjectTech = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 8px;
  span {
    &::after {
      display: inline-block;
      content: '/';
      margin: 0px 4px;
    }
  }
  span:last-child {
    &::after {
      display: none;
    }
  }
  @media ${device.mobileL} {
    font-size: 16px;
  }
  @media ${device.tablet} {
    flex-direction: column;
    gap: 6px;
    margin-top: 0px;
    font-size: 2vw;
    span {
      &::after {
        display: none;
      }
    }
  }
`;

export default Projects;
